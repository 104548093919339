import React from 'react';
import { graphql } from 'gatsby';
import Blog from '@src/pages/blog';

export default function BlogTag({ data, pageContext }) {
  const tagFilter = pageContext.tag;
  const filteredContent = data.contentfulPage.pageContent.filter(
    content =>
      content.tags &&
      content.tags.some(tag => tag === tagFilter),
  );
  const filteredData = {
    ...data,
    contentfulPage: {
      ...data.contentfulPage,
      pageContent: filteredContent,
    },
  };

  return <Blog data={filteredData}></Blog>;
}

export const query = graphql`
  query BlogCategory {
    contentfulPage(pageUrl: { eq: "/pressmeddelanden" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulTextSection {
          identifier
          overline
          title
          subtitle
          description {
            description
          }
          date
          image {
            file {
              url
            }
            title
          }
          tags
          categories {
            ... on ContentfulText {
              text
              href
            }
          }
        }
      }
    }
  }
`;
